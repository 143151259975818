<template>
  <div class="dashboard pb-12">
    <main-app-bar>
      <template v-slot:title> Mindsets </template>
      <template v-slot:top-actions>
        <v-btn
          class="bg-primary-gradient primary ml-auto"
          :to="{ name: 'new.mindset' }"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Mindset
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-7 px-10 pb-6">
      <div class="form-container">
        <div class="search-form">
          <v-row no-gutter>
            <v-col cols="12" sm="4">
              <v-text-field
                label="Search Mindsets"
                v-model="search"
                @input="searchChange"
                hide-details
                clearable
                rounded
                solo
                flat
              />
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" sm="2">
              <v-text-field
                v-mask="'###'"
                v-model="filter.week"
                @input="filterWeekChange"
                label="Filter by Week"
                clearable
                rounded
                solo
                flat
              />
            </v-col>

            <v-col cols="12" sm="4">
              <video-programs
                v-model="filter.programs"
                label="Filter by Programs"
                placeholder="Filter by Programs and Challenge"
                @change="filterProgramChange"
                return-object
                is-for-filter
                clearable
                multiple
                rounded
                solo
                flat
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-row>
        <v-col cols="12">
          <mindset-list
            :meta="meta"
            :loading="loading"
            :mindsets="mindsets"
            @loadMore="loadMoreVideos"
          />
        </v-col>
      </v-row>
    </div>

    <v-toast ref="toast" />
  </div>
</template>

<script>
import VideoPrograms from '@/components/elements/programs/SearchProgramsInput'
import MainAppBar from '@/layouts/shared/MainAppBar'
import MindsetList from '../components/MindsetList'
import VToast from '@/components/elements/Toast'

import { mapState, mapActions, mapMutations } from 'vuex'
import { map, isEmpty, debounce } from 'lodash'
import { mask } from 'vue-the-mask'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'MindsetsPage',

  directives: {
    mask,
  },

  components: {
    VToast,
    MainAppBar,
    MindsetList,
    VideoPrograms,
  },

  data() {
    return {
      search: null,
      loading: false,

      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.mindsets.filter,
      meta: (state) => state.mindsets.mindsets.listMeta,
      mindsets: (state) => state.mindsets.mindsets.list,
    }),
  },

  methods: {
    ...mapActions({
      getMindsets: 'mindsets/getMindsets',
    }),

    ...mapMutations({
      setMindsetsList: 'mindsets/setMindsetsList',
      clearMindsetList: 'mindsets/clearMindsetList',
      setMindsetsListMeta: 'mindsets/setMindsetsListMeta',
    }),

    searchChange(key) {
      this.searcMindsets()
    },

    filterWeekChange(value) {
      this.searcMindsets()
    },

    filterProgramChange(value) {
      if (isEmpty(value)) {
        this.fetchMindsets()
      } else {
        this.searcMindsets()
      }
    },

    searcMindsets: debounce(function(test) {
      this.clearMindsetList()
      this.fetchMindsets()
    }, 600),

    loadMoreVideos() {
      if (this.meta.current_page < this.meta.last_page) {
        this.fetchMindsets(this.meta.current_page + 1)
      }
    },

    async fetchMindsets(page = 1) {
      if (this.loading) return

      this.loading = true

      let params = {
        page,
        search: this.search,
        week: this.filter.week,
        programs: map(this.filter.programs, (prg) => prg.id),
      }

      await this.getMindsets(params)
        .then(({ data, meta }) => {
          this.setMindsetsList(data)
          this.setMindsetsListMeta(meta)
        })
        .catch(() => {
          this.$refs.toast.open({
            color: 'red',
            message: 'Error on loading lists',
          })
        })

      await this.$sleep()

      this.loading = false
    },
  },
}
</script>
