<template>
  <div class="bbr-mindsets--list">
    <v-simple-table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Week</th>
          <th>Day</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
        infinite-scroll-immediate-check
        infinite-scroll-distance="50"
      >
        <tr
          class="clickable new-tab-link-row"
          v-for="mindset in mindsets"
          :key="mindset.id"
        >
          <td class="py-4">
            <h2 class="subtitle-1">{{ mindset.title }}</h2>

            <router-link
              :to="{ name: 'mindset', params: { id: mindset.id } }"
              class="row-link"
            />
          </td>
          <td class="py-4">
            <div class="truncate--3">
              {{ mindset.description }}
            </div>

            <router-link
              :to="{ name: 'mindset', params: { id: mindset.id } }"
              class="row-link"
            />
          </td>
          <td class="py-4" width="10%">
            Week {{ mindset.week }}
            <router-link
              :to="{ name: 'mindset', params: { id: mindset.id } }"
              class="row-link"
            />
          </td>
          <td class="py-4" width="10%">
            {{ mindset.mindsetDay | title }}
            <router-link
              :to="{ name: 'mindset', params: { id: mindset.id } }"
              class="row-link"
            />
          </td>
        </tr>
      </tbody>
      <table-skeleton-loader v-if="loading" column="4" row />
    </v-simple-table>

    <no-list v-if="!hasMindsets && !loading" details="No Available Mindsets" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

import { mdiDotsVertical } from '@mdi/js'

export default {
  name: 'MindsetList',

  components: {
    NoList,
    TableSkeletonLoader,
  },

  directives: {
    infiniteScroll,
  },

  props: {
    mindsets: {
      type: Array,
      default: () => [],
      required: true,
    },

    meta: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
    }
  },

  computed: {
    hasMindsets() {
      return !!this.mindsets.length
    },

    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.mindset {
  &--description {
    max-width: 300px !important;
  }
}
</style>
