<template>
  <div class="bbr-mindsets">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="mindset.id"> {{ mindset.title }} </span>
        <span v-if="!$attrs.id"> New Mindset </span>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MindsetPage',

  components: {
    MainAppBar,
  },

  computed: {
    ...mapGetters({
      mindset: 'mindsets/getSelecteMindset',
    }),
  },

  methods: {
    ...mapMutations({
      clearSelectedMindset: 'mindsets/clearSelectedMindset',
    }),
  },

  beforeRouteLeave(to, from, next) {
    this.clearSelectedMindset()

    next()
  },
}
</script>
